import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/img/:catchAll(.*)*",
    component: () => import("../views/e404.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/explore",
    name: "explore",
    component: () => import(/* webpackChunkName: "about" */ "../views/Explore.vue"),
  },

  {
    path: "/new",
    name: "newProject",
    component: () => import(/* webpackChunkName: "about" */ "../views/NewProject.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/:project",
    component: () => import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "endpoints",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardEndpoints.vue"),
      },
      {
        path: "stats",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardStats.vue"),
      },
      {
        path: "design",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardDesign.vue"),
      },

      {
        path: "design/import",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardImportCsv.vue"),
      },
      {
        path: "design/export",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardExport.vue"),
      },
      {
        path: "airtable",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardAirtable.vue"),
      },
      {
        path: "packages",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardPackages.vue"),
      },
      {
        path: "deploy",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardDeploy.vue"),
      },
      {
        path: "settings",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardSettings.vue"),
      },
      {
        path: "settings/server",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardServerSettings.vue"),
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: "/",
        component: () => import(/* webpackChunkName: "about" */ "../views/DashboardEndpoints.vue"),
      },
    ],
  },
  {
    // UserPosts will be rendered inside User's <router-view>
    // when /user/:id/posts is matched
    path: "/:project/:operation",
    component: () => import(/* webpackChunkName: "about" */ "../views/Operation.vue"),
    children: [
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: "/",
        component: () => import(/* webpackChunkName: "about" */ "../views/OperationPlayground.vue"),
      },

      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: "playground",
        component: () => import(/* webpackChunkName: "about" */ "../views/OperationPlayground.vue"),
      },

      {
        name: "operationHome",
        path: "use", // path: "/",
        component: () => import(/* webpackChunkName: "about" */ "../views/OperationHome.vue"),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "config",
        component: () => import(/* webpackChunkName: "about" */ "../views/OperationEdit.vue"),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "code",
        component: () => import(/* webpackChunkName: "about" */ "../views/OperationCode.vue"),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "logs",
        component: () => import(/* webpackChunkName: "about" */ "../views/OperationLogs.vue"),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "insights",
        component: () => import(/* webpackChunkName: "about" */ "../views/OperationAna.vue"), // "../views/OperationInsights.vue"
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
