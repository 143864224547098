<template>
  <div class="homePublic" style="margin-top: -120px">
    <section class="hero is-medium is-main is-darkNO">
      <div class="hero-body" style="padding-bottom: 4rem">
        <br />
        <br />

        <!-- 

          Fullstack doesn't need to be so messy.

Launch modern APIs using a comprehensive toolkit.

        -->
        <p class="title is-3">
          AI-driven and growing fast.
          <!--    AI + APIs = The Future of Fullstack -->
        </p>
        <p class="subtitle is-4">Our 50+ magazine brands are redefining the rules.</p>
        <!-- 
        <router-link to="/brands">
          <b-button type="is-dark" style="font-weight: bold">Explore brands </b-button>
        </router-link>

        <waitlist style="max-width: 300px" />

 
         -->
      </div>
    </section>
    <br />
    <br />

    <div class="section">
      <div class="container home">
        <p class="title is-3">
          A new era has begun

          <!--

 
          -->
        </p>
        <p class="subtitle is-5">We're pioneering generative AI and personlized content</p>

        <div class="columns">
          <div class="column" v-for="feature in keyFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  {{ feature.description }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- eo col-->
      </div>
    </div>

    <div class="section" id="pricing" style="display: none">
      <div class="container">
        <div class=" " style="text-align: center">
          <p class="title is-2">Our Portfolio</p>
          <p class="subtitle is-5">Modern brands for real people</p>
          <br />
        </div>

        <div class="columns">
          <!--  
          <div class="column" v-for="feature in howItWorks" :key="feature.title">
            <h3 class="title" style="margin-bottom: 6px">{{ feature.title }}</h3>
            <div class="content">
              <ul>
                <li v-for="item in feature.items" :key="item" v-html="item"></li>
              </ul>
            </div>
          </div>

-->
        </div>
      </div>
    </div>

    <!--eo how-->
    <!--
    <div class="section" id="pricing">
      <div class="container home" style="text-align: center">
        <p class="title is-2">Work smarter, not harder</p>
        <p class="subtitle is-5">Cancel anytime. Host your apps anywhere.</p>
        <pricing />
      </div>
    </div>
    eo priicng-->

    <div class="section" id=" ">
      <div class="container footer" style="text-align: left">
        <p class=" ">© Endgame media group, {{ new Date().getFullYear() }} ⚡</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";

import pricing from "@/components/brochure/pricing.vue";

const keyFeatures = [
  {
    title: "Infinite newsroom ♾️", //invinite newsrom
    description:
      "Our platform transcends the limitations of a traditional newsroom. We produce a broad array of content, catering to a diverse range of interests and demographics. From breaking news to in-depth features, we ensure a constant flow of engaging content.",
  },

  {
    title: "Empathy at the core 💚",
    description:
      "We understand our readers like no other media.   We connect with them throught discussions, currated experiences and contextualized pieces. We're not just creating content; we're building connections. ",
  },
  {
    title: "Hyper niche 🎯 ",
    description:
      " We sift through the noise to deliver content that truly resonate with unique audiences. We nurture passionate communities and build lasting relationships with our readers. ",
  },
  {
    title: "Research lab 🧪 ",
    description:
      " As we continuously refine our algorithms, we consistently elevate the quality of our content. Although there are some current limitations, we are on a clear path not just to match, but to exceed the capabilities of even the most talented human writers. ",
  },
];

const howItWorks = [
  {
    title: "Design",
    items: [
      "Draft your API using AI",
      "Make key implementation decisions",
      "Organize your endpoints",
      "Limit access by roles",
      //   "Choose key libraries and implementation strategies",

      "Generate documentation & frontend SDKs",
    ],
  },
  {
    title: "Dev",
    items: [
      "Refine AI-generated functions",
      "Minimal, expressive syntax",
      "Batteries included CLI",
      "<code>Endgame dev</code> keeps everything in sync",
      "Debug using the API playground",
      "Launch with <code>Endgame deploy</code>",
    ],
  },
  {
    title: "Manage",
    items: [
      "Monitor API usage",
      "Fine-tune cache durations",
      "Rate-limit sensitive calls",
      "Optimize slow functions",
      "Scale your servers",
    ],
  },
];

/*[
  {
    title: "Speed Up Full-Stack Development",
    description:
      "Endgame centralizes all key information to save you time. Our development tools streamline the process, keeping specifications in sync and simplifying debugging.",
  },
  {
    title: "Effortless API Generation",
    description:
      "Endgame's tools help you write your API specification and generate draft code for all functions with ease. Whether you're an experienced coder or just starting out, you can easily adjust the details to fit your needs.",
  },
  {
    title: "Freedom to Customize",
    description:
      "Our open-source based toolkit gives you the flexibility you need to succeed. Avoid platform and vendor lock-ins and scale with ease, without any hidden fees. Endgame gives you the freedom to build the solution that's right for you.",
  },
];
*/
/*


[
  {
    title: "AI-Optimized API Development",
    description:
      "Endgame leverages AI algorithms to optimize API development, freeing up time and improving efficiency.",
  },
  {
    title: "Predictive Coding",
    description: "Real-time code suggestions and completion to save time and reduce errors.",
  },
  {
    title: "Flexible Toolkit",
    description: "Customizable options to fit your specific needs and build the APIs you need to succeed.",
  },
];*/

export default {
  name: "Home",
  components: {
    //  waitlist,
    //  pricing,
    //  HelloWorld,
    // projCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Endgame • AI-driven media group", //"ApiBros 😎",
    // all titles will be injected into this template
    // titleTemplate: "%s  🍔  ",
    //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
  data() {
    return {
      keyFeatures,
      howItWorks,
      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
  },
};
</script>
<style scoped>
.hero.is-main {
  background-color: #167df0;
  color: #fff;
  background: linear-gradient(150deg, #ffc92f, #ff185c, #f316d5, #b81ed2);

  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
}
</style>
