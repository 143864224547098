export const MethodColors = {
  POST: "is-success",
  DELETE: "is-danger",
  PUT: "is-warning",
  PATCH: "is-warning",
  GET: "is-info",
};

import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);
// Create formatter (English).
const myTimeAgo = new TimeAgo("en-US");
myTimeAgo.format(new Date());
// "just now"
export function timeAgo(dateStr) {
  return myTimeAgo.format(new Date(dateStr));
  // const myDate = new Date('2023-02-12T08:30:00');
  //console.log(timeAgo(myDate));
  var date = new Date(dateStr);
  const formatter = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  var difference = date - Date.now() * -1;
  let unit;
  let magnitude;
  console.log("DIFF" + difference);
  if (difference < 1000) {
    unit = "second";
    magnitude = difference / 1000;
  } else if (difference < 1000 * 60) {
    unit = "minute";
    magnitude = difference / 1000 / 60;
  } else if (difference < 1000 * 60 * 60) {
    unit = "hour";
    magnitude = difference / 1000 / 60 / 60;
  } else if (difference < 1000 * 60 * 60 * 24) {
    unit = "day";
    magnitude = difference / 1000 / 60 / 60 / 24;
  } else {
    unit = "month";
    magnitude = difference / 1000 / 60 / 60 / 24 / 30;
  }

  return formatter.format(Math.floor(magnitude), unit);
}

// const { match } = require('node-match-path')
import { match as matchLib } from "node-match-path";
export function matchPath(path, route) {
  return matchLib(path, route);
  //match('/user/:userId', '/user/5')
}

/*
{
  matches: true,
  params: {
    userId: '5'
  }
}
*/
