<template>
  <div class="pricing-table" style="text-align: center">
    <div v-for="plan in plans" :key="plan.id" :class="['pricing-plan', plan.highlight]">
      <div class="plan-header">{{ plan.name }}</div>
      <div class="plan-price">
        <span class="plan-price-amount"
          ><span class="plan-price-currency">{{ plan.currency }}</span
          >{{ plan.price }}</span
        >/month
      </div>

      <!-- pluralize-->
      <div class="plan-users" style="margin-top: 20px">{{ plan.users }} {{ plan.users > 1 ? "users" : "user" }}</div>
      <div class="plan-users" style="margin-top: 0px">
        {{ plan.projects }} {{ plan.users > 1 ? "projects" : "project" }}
      </div>

      <!-- 
      <div class="plan-onboarding" style="margin-top: 10px">
        {{ plan.onboarding }}
      </div>  -->

      <div class="plan-tagline title is-6" style="">
        {{ plan.tagline }}
      </div>

      <div class="plan-items">
        <div v-for="(item, index) in plan.features" :key="index" class="plan-item">{{ item }}</div>
      </div>
      <div class="plan-footer">
        <div v-if="plan.free" class="plan-free">No signup required</div>
        <div v-else class="plan-btn">
          <!--  
          <button class="button is-fullwidth" :disabled="plan.currentPlan">Current plan</button> -->
          <button class="button is-fullwidth" @click="email(plan.name)">Get started</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bulma-pricingtable/dist/css/bulma-pricingtable.min.css";

export default {
  methods: {
    email(plan) {
      window.location.href = `mailto:team@mid.run?subject=Intrested in the Midrun ${plan.name} plan &body=Hi, \nI'm interested in the Midrun ${plan} plan. My needs...`;
      // window.location.href = "mailto:
    },
  },
  data() {
    return {
      plans: [
        {
          id: 1,
          name: "Basic",
          price: 185,
          users: "1",
          projects: "1",
          onboarding: "One hour setup call",
          tagline: "All you need to get started",
          currency: "$",
          features: [
            "API Dashboard",
            "Examples projects",
            "AI code generation",
            "One hour setup call",
            "Hosted deployment",
            //   "Staging server",
            "CLI, devtools & clients",
            // "Client libraries",
          ],
          highlight: "",
        },
        {
          id: 2,
          name: "Plus",
          price: 740,
          users: "5",
          projects: "5",
          onboarding: "Up to 6 hours training",
          tagline: "Everything in basic +",
          currency: "$",
          features: ["Longer log retention", "Priority support", "Bigger instances", "Up to 6 hours training"],
          highlight: "is-success",
        },
        {
          id: 3,
          name: "Pro",
          price: "2200",
          users: "15",
          projects: "15",
          onboarding: "10+ hours of training",
          tagline: "Everything in plus +",
          currency: "$",
          features: [
            //   "Public docs & playground",
            "Public API playground access  ",
            "Contributor timetracking",

            "Full Midrun API access",
            "10+ hours of training",
          ],
          highlight: "is-warning",
        },
        {
          id: 4,
          name: "Custom",
          price: 0,
          users: "",
          projects: "",
          onboarding: "",
          tagline: "Everything in pro +",
          currency: "$",
          features: ["Advanced automations", "Custom integrations", "On-premise hosting", "Contract terms"],
          highlight: "is-dark",
        },
      ],
    };
  },
};
</script>
<style scoped>
.plan-items {
  margin: 10px;
}

.plan-tagline {
  margin-top: 20px;
  margin-bottom: 0px !important;
}
</style>
